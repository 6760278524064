
import local from '@/api/lib/co.lib.local'
import cookie from '@/api/lib/co.lib.cookie'
import sso from '@/api/lib/co.lib.sso'

const coLib = {
  local,
  cookie,
  sso
}

export default coLib
