<template>
  <land-section
    id="my_info"
    space="40"
  >
    <v-container
      style="max-width: 1000px;"
    >
      <v-row
        no-gutters
        justify="center"
      >
        <v-col
          cols="12"
          md="3"
          class="hidden-sm-and-down"
        >
          <div class="d-flex flex-column grey lighten-4 pl-6 pt-6 mr-8">
            <land-categories
              :title="catAll.title"
              :items="catAll.items"
              :active="catAll.active"
              active-color="grey lighten-2"
              @click="onCategory"
            />
          </div>
        </v-col>
        <v-col
          cols="12"
          md="9"
        >
          <v-card
            class="overflow-y-auto overflow-x-hidden grey lighten-4 pa-6"
            flat
          >
            <template v-if="catNow === catNames.account">
              <info-account
                :info="userDetail"
                @confirm="onConfirm"
              />
            </template>

            <template v-if="catNow === catNames.person">
              <info-person
                :info="userDetail"
                @confirm="onConfirm"
              />
            </template>

            <template v-if="catNow === catNames.org">
              <info-org
                :info="userDetail"
                @confirm="onConfirm"
              />
            </template>
            <template v-if="catNow === catNames.phone">
              <info-bind-mobile :info="userDetail" />
            </template>
            <template v-if="catNow === catNames.password">
              <info-change-password :info="userDetail" />
            </template>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </land-section>
</template>

<script>
  /**
   * {
        name: CatNames.phone,
        title: '绑定手机号'
      },
   */
  import api from '@/api/co.api'
  import lib from '@/api/co.lib'
  import user from '@/api/co.user'

  const CatNames = {
    account: 'my-info-account',
    person: 'my-info-person',
    org: 'my-info-org',
    phone: 'my-info-phone',
    password: 'my-info-password'
  }

  export default {
    metaInfo: { title: '个人设置' },
    components: {
      InfoAccount: () => import('@/pages/sections/my/InfoAccount.vue'),
      InfoPerson: () => import('@/pages/sections/my/InfoPerson.vue'),
      InfoOrg: () => import('@/pages/sections/my/InfoOrg.vue'),
      InfoBindMobile: () => import('@/pages/sections/my/InfoBindMobile.vue'),
      InfoChangePassword: () => import('@/pages/sections/my/InfoChangePassword.vue'),
    },
    data () {
      return {
        userInfo: {},
        userDetail: {},
        catNames: CatNames,
        catNow: CatNames.account,
        catAll: {
          title: '个人设置',
          name: 'info',
          active: 0,
          items: [{
            name: CatNames.account,
            title: '账号信息设置'
          }, {
            name: CatNames.person,
            title: '个人基本资料'
          }, {
            name: CatNames.org,
            title: '单位/学校信息'
          }, {
            name: CatNames.password,
            title: '修改密码'
          }]
        }
      }
    },
    created () {
      this.userInfo = user.info.get()
      const name = api.link.info.name
      const params = JSON.parse(lib.cookie.get(name))
      this.setActive(params)

      this.getDetail()
    },
    methods: {
      onConfirm (data) {
        // console.log('onConfirm data: %o', data)
        for (const key in data) {
          this.$set(this.userDetail, key, data[key])
        }
      },
      onCategory (ev) {
        const item = ev.item
        this.catNow = item.name
      },
      setActive (params) {
        const name = `my-info-${params.name}`
        this.catNow = name
        for (const i in this.catAll.items) {
          const item = this.catAll.items[i]
          if (item.name === name) {
            this.catAll.active = parseInt(i, 10)
            break
          }
        }
      },
      getDetail () {
        const me = this
        const executed = function (res) {
          // console.log({ res })
          if (res.status) {
            me.userDetail = res.data
          }
        }

        user.info.gain({
          executed
        })
      },
    }
  }
</script>
