
import api from '@/api/co.api.js'

const libSso = {
  bindMobile ({
    mobile,
    code,
    executing = null,
    executed = null
  }) {
    if (!mobile) {
      console.warn('co.lib.sso bindMobile mobile is null')
      return
    }

    api.httpx.pull({
      url: api.url.web_sso_bind_mobile,
      data: {
        mobile,
        code
      },
      executing,
      executed
    })
  },
  sendMsgCode ({
    mobile,
    executing,
    executed
  }) {
    if (!mobile) {
      console.warn('co.lib.sso sendMsgCode mobile is null')
      return
    }

    api.httpx.pull({
      url: api.url.web_sso_send_msg_code,
      data: {
        mobile
      },
      executing,
      executed
    })
  },
  updatePasswordByCode ({
    newPassword,
    code,
    executing,
    executed
  }) {
    api.httpx.pull({
      url: api.url.web_sso_update_pwd_by_mobile,
      data: {
        code,
        newPassword
      },
      executing,
      executed
    })
  },
  forgetPassword ({
    mobile,
    newPassword,
    code,
    executing = null,
    executed = null
  }) {
    api.httpx.pull({
      url: api.url.web_sso_forget_pwd,
      data: {
        mobile,
        code,
        newPassword
      },
      executing,
      executed
    })
  },
  setPassword ({
    password,
    executing = null,
    executed = null
  }) {
    api.httpx.pull({
      url: api.url.web_sso_set_pwd,
      data: {
        password
      },
      executing,
      executed
    })
  }
}

export default libSso
